// Модуль Управление данными

import {
  ApiHelper,
  Dictionary,
  EventMoveMode,
  Logger,
  NotificationsService,
  queueIsChangeCells,
  queueIsDeleteRows,
  TableDataSet,
  TableRow,
  UpdateRowIndexDto,
  VocValueStatusEnum,
} from "table";
import {
  IRepositoryVocQueue,
  IRepositoryVocQueueOnly,
  IRepositoryVocQueueOnlyChangeCells,
  IRepositoryVocQueueOnlyDelete,
  IRepositoryVocQueueOnlySave,
} from "@/modules/editing-voc/services/ApiVocService/types";
import {
  IChangeCellAndPointer,
  IRepository,
  IRepositoryEventContext,
  IRepositoryLoading,
  IRepositoryQueueOnlyChangeCells,
  IRepositoryQueueOnlyDeleteRows,
  IRepositoryQueueOnlyMoveRow,
  IRepositoryQueueOnlyUpdateRowIndexes,
  IRepositoryTableCellAndPointer,
  IRepositoryTableRowAndPointer,
} from "table/dist/types/IRepository";
import {
  TableCellPointer,
  TablePointerRow,
} from "table/dist/components/TableComponent/common/types/TableCellPointer";
import ApiVocService from "@/modules/editing-voc/services/ApiVocService/ApiVocService";
import { VocValueDto } from "table/dist/services/Api/types/VocRepositoryDto";
import { TableRowChange } from "table/dist/types/TableRowRecord";
import { TableRowObj } from "table/dist/types/TableRowObj";

export function queueIsSave(
  queue: IRepositoryVocQueueOnly,
): queue is IRepositoryVocQueueOnlySave {
  return queue.type === "save";
}

export function queueIsDelete(
  queue: IRepositoryVocQueueOnly,
): queue is IRepositoryVocQueueOnlyDelete {
  return queue.type === "delete";
}

export default class TableVocRepository
  implements
    IRepository<
      IRepositoryVocQueueOnly,
      IRepositoryVocQueue,
      IRepositoryLoading<IRepositoryVocQueue>
    >
{
  intervals: any[] = [];
  _executeFree = true;
  _lastError = false;
  _bufferingData: IRepositoryVocQueueOnly[] | undefined = undefined;
  queue: IRepositoryVocQueue = [];

  // запуск интервала для сохранения изменений в таблице
  runSaveInterval(tableDataSet: TableDataSet) {
    this.intervals.push(
      setInterval(async () => {
        if (this.queue.length === 0 || !this._executeFree) {
          return;
        }

        // сообщаем очереди, что задача выполняется
        this._executeFree = false;

        const task = this.queue[0];
        // запускаем выполнение задачи
        try {
          const result = await this.execute(tableDataSet, task);
          // после успешного сохранения вытаскиваем задачу из очереди
          this.queue.shift();
          this._lastError = false;
        } catch (e: any) {
          const error = await ApiHelper.getError(e);
          if (error.value.not_repeat) {
            NotificationsService.send({
              type: "error",
              title: `Ошибка при сохранении изменений в справочнике. Несохранённые изменения отменены.`,
              text: error.getMessage(),
            });
            await tableDataSet.changes.cancelQueueChanges(tableDataSet);
            return;
          }

          this._lastError = true;
        }

        // сообщаем, что выполнение задачи окончено
        this._executeFree = true;
      }, 100),
    );
  }

  destroy() {
    this.intervals.forEach((x) => clearInterval(x));
  }

  async execute(
    tableDataSet: TableDataSet,
    queueOnly: IRepositoryVocQueueOnly,
  ): Promise<any> {
    const getParams: Dictionary = {
      voc_type: tableDataSet.options.extra!.voc_type,
    };
    if (queueIsDelete(queueOnly)) {
      getParams.record_id = queueOnly.data.record_id;
    }

    const result = await ApiVocService.postForm(
      queueOnly.type,
      queueOnly.data,
      getParams,
    );

    if (queueIsSave(queueOnly)) {
      if (!queueOnly.tableRow) {
        Logger.error("queueOnly.tableRow is undefined", { queueOnly });
        return result;
      }

      if (!queueOnly.tableRow.uuid) {
        Logger.error("queueOnly.tableRow.uuid is undefined", { queueOnly });
        return result;
      }

      const tableRow = tableDataSet.getRowByUUID(
        queueOnly.tableRow.uuid,
        queueOnly.tableRow.row,
      );
      const newVocValue = result.json as VocValueDto;
      if (!tableRow) {
        Logger.error("TableRow is undefined", { queueOnly });
        return result;
      }

      TableRow.setOriginal(tableRow, newVocValue);
    }

    return result;
  }

  queuePush(queueOnly: IRepositoryVocQueueOnly, isBuffer = false) {
    // если включен режим буферизации и это не просьба выполнения от буфера - записать в буфер
    if (this._bufferingData !== undefined && !isBuffer) {
      this._bufferingData.push(queueOnly);
      return;
    }

    this.queue.push(queueOnly);
  }

  addRow(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    rowChange: TableRowChange,
  ) {
    const queueOnly: IRepositoryVocQueueOnlySave = {
      type: "save",
      data: {
        voc_type: tableDataSet.options.extra?.voc_type,
        value_status: VocValueStatusEnum.Active,
        ...(rowChange.original || {}),
        attrs: rowChange.changeCells.reduce((attrs, cell) => {
          attrs[cell.pointer.col_name] = cell.changeCell.value;
          return attrs;
        }, {} as Dictionary),
      },
      changeEvents,
      tableRow: rowChange.tableRow,
      pointer: { row: rowChange.row },
    };
    this.queuePush(queueOnly);
  }

  changeTableCells(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    changeCells: IChangeCellAndPointer[],
  ) {
    const table_cells = changeCells.map((changeCellAndPointer) => {
      const tableRow = tableDataSet.getRowOrException(
        changeCellAndPointer.pointer.row,
      );
      const vocDto = tableRow.original as VocValueDto | undefined;
      if (!vocDto) {
        Logger.warn(
          `Невозможно редактировать строку без record_id, row = ${tableRow.row}`,
        );
        return;
      }

      return {
        pointer: {
          col_name: changeCellAndPointer.pointer.col_name,
          record_id: vocDto.record_id,
        },
        table_cell: { value: changeCellAndPointer.changeCell.value },
      };
    });
    if (table_cells.length === 0) {
      return;
    }

    this.queuePush({
      type: "change_cells",
      data: {
        table_cells,
      },
      changeEvents,
    } as IRepositoryVocQueueOnlyChangeCells);
  }

  deleteRow(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    pointersAndRows: {
      pointer: TablePointerRow;
      tableRow: TableRowObj;
    }[],
  ) {
    pointersAndRows.forEach((pointerAndRow) => {
      const { tableRow } = pointerAndRow;
      const vocDto = tableRow.original as VocValueDto | undefined;
      if (!vocDto) {
        Logger.warn(
          `Невозможно удалить строку без record_id, row = ${tableRow.row}`,
        );
        return;
      }

      this.queuePush({
        type: "delete",
        data: {
          record_id: vocDto.record_id,
        },
        changeEvents,
      } as IRepositoryVocQueueOnlyDelete);
    });
  }

  moveRow(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    pointer: TablePointerRow,
    move_mode: EventMoveMode,
  ) {
    this.queuePush({
      type: "move_row",
      data: {
        pointer,
        move_mode,
      },
      changeEvents,
    } as IRepositoryQueueOnlyMoveRow);
  }

  updateRowIndexes(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    indexes: UpdateRowIndexDto[],
  ) {
    this.queuePush({
      type: "update_row_indexes",
      data: {
        indexes,
      },
      changeEvents,
    } as IRepositoryQueueOnlyUpdateRowIndexes);
  }

  changeTableRows(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    table_cells: IRepositoryTableRowAndPointer[],
  ): any {}

  // Объединение нескольких задач в одну (поддерживается только массив с одинаковыми задачами)
  combineQueue(queue: IRepositoryVocQueueOnly[]): IRepositoryVocQueueOnly[] {
    if (queueIsChangeCells(queue[0])) {
      const table_cells: IRepositoryTableCellAndPointer[] = [];
      queue.forEach((x) => {
        table_cells.push(...x.data.table_cells);
      });

      return [
        {
          type: "change_cells",
          data: { table_cells },
        } as IRepositoryQueueOnlyChangeCells,
      ];
    }

    if (queueIsDeleteRows(queue[0])) {
      const pointers: TableCellPointer[] = [];
      queue.forEach((x) => {
        pointers.push(...x.data.pointers);
      });

      return [
        {
          type: "delete_rows",
          data: { pointers },
        } as IRepositoryQueueOnlyDeleteRows,
      ];
    }

    return queue;
  }

  startBuffering() {
    this._bufferingData = [];
  }

  finishBuffering() {
    if (this._bufferingData === undefined) {
      return;
    }
    const queue: IRepositoryVocQueueOnly[] = [];
    let changeCells: IRepositoryVocQueueOnly[] = [];
    let deleteRows: IRepositoryVocQueueOnly[] = [];

    const addSpecTasks = () => {
      if (changeCells.length > 0) {
        queue.push(...this.combineQueue(changeCells));
        changeCells = [];
      }

      if (deleteRows.length > 0) {
        queue.push(...this.combineQueue(deleteRows));
        deleteRows = [];
      }
    };

    this._bufferingData.forEach((bufferQueue) => {
      if (queueIsChangeCells(bufferQueue)) {
        changeCells.push(bufferQueue);
        return;
      }

      if (queueIsDeleteRows(bufferQueue)) {
        deleteRows.push(bufferQueue);
        return;
      }

      // сохраняем оригинальный порядок выполнения действий
      addSpecTasks();

      queue.push(bufferQueue);
    });

    // не забываем добавить последние оставшиеся специфичные задачи
    addSpecTasks();

    queue.forEach((x) => this.queuePush(x, true));
    this._bufferingData = undefined;
  }
}
