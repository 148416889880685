import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "editing-voc-values default-page-container" }
const _hoisted_2 = { class: "flex-column default-page-padding-inline" }
const _hoisted_3 = {
  class: "app-h-s22-h24-w7-c333",
  style: {"margin-bottom":"10px"}
}
const _hoisted_4 = { style: {"display":"flex","justify-content":"space-between","margin-bottom":"10px"} }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { style: {"display":"flex","justify-content":"flex-end","gap":"20px"} }
const _hoisted_7 = { class: "default-page-padding-inline" }
const _hoisted_8 = { class: "table-scroll-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderSaveIndicator = _resolveComponent("HeaderSaveIndicator")!
  const _component_SwitchHints = _resolveComponent("SwitchHints")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_ActionsButtons = _resolveComponent("ActionsButtons")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_TablePaginationBottom = _resolveComponent("TablePaginationBottom")!
  const _component_EditingVocExtendedModal = _resolveComponent("EditingVocExtendedModal")!
  const _component_EditingVocModal = _resolveComponent("EditingVocModal")!
  const _component_EditableHelpComponent = _resolveComponent("EditableHelpComponent")!
  const _component_EnterModalComponent = _resolveComponent("EnterModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, {
      to: '#' + _ctx.HEADER_IDS.left
    }, [
      _createVNode(_component_HeaderSaveIndicator, {
        "table-data-set": _ctx.table.tableDataSet
      }, null, 8, ["table-data-set"])
    ], 8, ["to"])),
    (_openBlock(), _createBlock(_Teleport, {
      to: '#' + _ctx.HEADER_IDS.right
    }, [
      _createVNode(_component_SwitchHints, {
        "model-value": _ctx.helpHelper.visible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.helpHelper.setVisible($event)))
      }, null, 8, ["model-value"])
    ], 8, ["to"])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.vocDto?.voc_name), 1),
        (_ctx.table.tableDataSet?.isTableMounted)
          ? (_openBlock(), _createBlock(_Teleport, {
              key: 0,
              disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
              to: '#' + _ctx.table.tableDataSet.idContainerDivUp
            }, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.vocRepositoryDto)
                  ? (_openBlock(), _createBlock(_component_TableActionsComponent, {
                      key: 0,
                      "changes-state": _ctx.vocRepositoryDto.edit.edit ? 'auto' : 'hide',
                      "table-data-set": _ctx.table.tableDataSet,
                      "filter-state": "enable",
                      "row-append-state": "hide"
                    }, {
                      left: _withCtx(() => [
                        (_ctx.vocExtraInfo?.edit.create_update === true)
                          ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                              key: 0,
                              icon: _ctx.APP_ICONS.documentOutline,
                              class: "button-opacity",
                              title: "Создать новую запись в справочнике",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open.vocEntryModal = { mode: 'create' }))
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["changes-state", "table-data-set"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5)),
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.actions.length)
                    ? (_openBlock(), _createBlock(_component_ActionsButtons, {
                        key: 0,
                        "data-array": _ctx.actions,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onActionExec($event.data)))
                      }, null, 8, ["data-array"]))
                    : _createCommentVNode("", true),
                  (
                _ctx.vocExtraInfo?.edit.create_update === true &&
                _ctx.params.voc_type !== _ctx.VOC_NAMES_DICT.organizations &&
                _ctx.vocRepositoryDto?.edit.edit === true
              )
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 1,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.table.edit = !_ctx.table.edit))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.table.edit
                  ? "Завершить редактирование справочника"
                  : "Редактировать справочник"), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 8, ["disabled", "to"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.table.tableDataSet === null || _ctx.table.factory === null)
        ? (_openBlock(), _createBlock(_component_ScreenSpinner, {
            key: 0,
            dimming: false
          }))
        : (_ctx.table.tableDataSet && _ctx.table.editingVocDataSet)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_7, [
                (_ctx.table.tableDataSet.isTableMounted)
                  ? (_openBlock(), _createBlock(_Teleport, {
                      key: 0,
                      disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                      to: '#' + _ctx.table.tableDataSet.idContainerDivUp
                    }, [
                      (_ctx.table.tableDataSet)
                        ? (_openBlock(), _createBlock(_component_PanelFilters, {
                            key: 0,
                            "data-set": _ctx.table.tableDataSet.options.filtersSet,
                            "model-array": _ctx.table.tableDataSet.modelUnref,
                            "table-data-set": _ctx.table.tableDataSet,
                            types: _ctx.table.tableDataSet.types,
                            style: {"margin-bottom":"10px"},
                            "url-sync": ""
                          }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
                        : _createCommentVNode("", true)
                    ], 8, ["disabled", "to"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_TableComponent, {
                  classes: _ctx.VOC_CLASSES,
                  isShowInfo: false,
                  isViewColumnNumber: false,
                  "screen-spinner": _ctx.table.editingVocDataSet.pagination.loading,
                  "table-data-set": _ctx.table.tableDataSet,
                  "onUpdate:cells": _ctx.onUpdateCells,
                  onRowInsert: _cache[4] || (_cache[4] = ($event: any) => (_ctx.table.tableDataSet.rowInsert($event))),
                  onRowDelete: _ctx.onRowDelete,
                  onCellKeydown: _ctx.onCellKeyDown,
                  onCellDblClick: _ctx.onCellDblClick,
                  "onUpdate:cursor": _cache[5] || (_cache[5] = ($event: any) => (_ctx.setHelp($event)))
                }, {
                  headers: _withCtx(({ classes, tableDataSet }) => [
                    _createVNode(_component_TableMultiHeaders, {
                      classes: classes,
                      "left-col": null,
                      "table-data-set": tableDataSet,
                      onClickLeftCol: ($event: any) => (tableDataSet.selectedAll(this.$nextTick))
                    }, null, 8, ["classes", "table-data-set", "onClickLeftCol"])
                  ]),
                  areport_name: _withCtx(({
              tableDataSet,
              model,
              colIndex,
              rowIndex,
              data,
              original,
              classes,
              type,
            }) => [
                    _createElementVNode("span", null, [
                      _createVNode(_component_router_link, {
                        title: original.areport_name,
                        to: `/editor-forms/${original.areport_id}`
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(data.value), 1)
                        ]),
                        _: 2
                      }, 1032, ["title", "to"])
                    ])
                  ]),
                  _: 1
                }, 8, ["classes", "screen-spinner", "table-data-set", "onUpdate:cells", "onRowDelete", "onCellKeydown", "onCellDblClick"])
              ]),
              (_ctx.table.tableDataSet.isTableMounted)
                ? (_openBlock(), _createBlock(_Teleport, {
                    key: 0,
                    disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                    to: '#' + _ctx.table.tableDataSet.idContainerDivBottom
                  }, [
                    _createVNode(_component_TablePaginationBottom, {
                      "table-data-set": _ctx.table.tableDataSet,
                      onPagination: _ctx.onPagination
                    }, null, 8, ["table-data-set", "onPagination"])
                  ], 8, ["disabled", "to"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
    ]),
    (_ctx.open.vocEntryModal && _ctx.vocRepositoryDto && _ctx.vocExtraInfo)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.params.voc_type === _ctx.VOC_NAMES_DICT.organizations)
            ? (_openBlock(), _createBlock(_component_EditingVocExtendedModal, {
                key: 0,
                mode: _ctx.open.vocEntryModal.mode,
                record_id: _ctx.open.vocEntryModal.record_id,
                "voc-extra-info": _ctx.vocExtraInfo,
                voc_type: _ctx.params.voc_type,
                onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.open.vocEntryModal = null)),
                onSuccess: _ctx.initVocType
              }, null, 8, ["mode", "record_id", "voc-extra-info", "voc_type", "onSuccess"]))
            : (_openBlock(), _createBlock(_component_EditingVocModal, {
                key: 1,
                mode: _ctx.open.vocEntryModal.mode,
                record_id: _ctx.open.vocEntryModal.record_id,
                "voc-extra-info": _ctx.vocExtraInfo,
                voc_type: _ctx.params.voc_type,
                onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.open.vocEntryModal = null)),
                onSuccess: _ctx.initVocType
              }, null, 8, ["mode", "record_id", "voc-extra-info", "voc_type", "onSuccess"]))
        ], 64))
      : _createCommentVNode("", true),
    (
      _ctx.vocExtraInfo &&
      _ctx.currentTableModelHelp &&
      _ctx.helpHelper.visible &&
      (_ctx.currentHelpValue || _ctx.vocExtraInfo.edit.hint)
    )
      ? (_openBlock(), _createBlock(_component_EditableHelpComponent, {
          key: 1,
          editable: _ctx.vocExtraInfo.edit.hint,
          modelValue: _ctx.currentHelpValue,
          title: _ctx.currentTableModelHelp.caption,
          style: {"z-index":"4"},
          onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.helpHelper.setHelp(null))),
          onClickEdit: _ctx.onOpenHelpEdit
        }, null, 8, ["editable", "modelValue", "title", "onClickEdit"]))
      : _createCommentVNode("", true),
    (_ctx.open.enterModal)
      ? (_openBlock(), _createBlock(_component_EnterModalComponent, {
          key: 2,
          "model-value": _ctx.open.enterModal.modelValue,
          readonly: _ctx.open.enterModal.readonly,
          title: _ctx.open.enterModal.title,
          "title-save-button": _ctx.open.enterModal.titleSaveButton,
          onClose: _ctx.open.enterModal.onClose,
          "onUpdate:modelValue": _ctx.open.enterModal.onUpdateModelValue
        }, null, 8, ["model-value", "readonly", "title", "title-save-button", "onClose", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}