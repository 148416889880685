
import {
  ApiHelper,
  ApiService,
  ButtonComponent,
  EditableHelpComponent,
  EnterModalComponent,
  EnterModalProps,
  GlobalContext,
  HelpHelper,
  KeyboardEventHelper,
  Logger,
  NotificationsService,
  PanelFilters,
  PanelFiltersSet,
  ScreenSpinner,
  TABLE_ICONS,
  TableActionsComponent,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableModel,
  TableMultiHeaders,
  TablePaginationBottom,
  VOC_NAMES_DICT,
  VocDataSet,
} from "table";
import {
  EditingVocDataSet,
  EditingVocDataSetStaticParams,
} from "@/modules/editing-voc/classes/EditingVocDataSet";
import { defineComponent } from "vue";
import {
  EditingVocDataSetData,
  VocExtraInfo,
} from "@/modules/editing-voc/common/types";
import { TableEmitUpdateCell } from "table/dist/components/TableComponent/common/types/TableEmitUpdateCell";
import {
  APP_ICONS,
  HEADER_IDS,
  VOC_CLASSES,
} from "@/common/consts";
import SwitchHints from "@/components/smart/SwitchHints.vue";
import ApiVocService from "@/modules/editing-voc/services/ApiVocService/ApiVocService";
import {
  VocDto,
  VocRepositoryDto,
  VocValueDto,
} from "table/dist/services/Api/types/VocRepositoryDto";
import EditingVocModal, { EditingVocModalMode } from "@/modules/editing-voc/components/EditingVocModal.vue";
import EditingVocExtendedModal from "@/modules/editing-voc/components/EditingVocExtendedModal.vue";
import TableVocRepository from "@/modules/editing-voc/classes/TableVocRepository";
import { TableCellPointer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import AppApiService from "@/services/AppApiService/AppApiService";
import { PaginationMode } from "table/dist/types/LazyPaginationState";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import HeaderSaveIndicator from "@/components/HeaderSaveIndicator.vue";
import EditingVocEntry from "@/modules/editing-voc/components/EditingVocEntry.vue";
import { ActionButtonDto } from "@/services/ApiActions/types";
import { PageGlobalContext } from "table/dist/services/GlobalContext/types";
import ApiActionsService, { RefreshType } from "@/services/ApiActions/ApiActionsService";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import { HelpHelperTableContext } from "table/dist/classes/HelpHelper";

// Компонент для отображения части атрибутов из записи справочника
export default defineComponent({
  name: "EditingVocEntries",
  components: {
    PanelFilters,
    ScreenSpinner,
    SwitchHints,
    EditingVocModal,
    EditingVocExtendedModal,
    // eslint-disable-next-line vue/no-unused-components
    EditingVocEntry,
    TableComponent,
    TableMultiHeaders,
    ButtonComponent,
    TablePaginationBottom,
    EditableHelpComponent,
    EnterModalComponent,
    TableActionsComponent,
    HeaderSaveIndicator,
    ActionsButtons,
  },
  setup() {
    return {
      APP_ICONS,
      VOC_CLASSES,
      VOC_NAMES_DICT,
      TABLE_ICONS,
      HEADER_IDS,
    };
  },
  data() {
    return {
      vocList: null as VocDto[] | null,
      vocRepositoryDto: null as null | VocRepositoryDto,
      vocExtraInfo: null as null | VocExtraInfo,
      values: null as null | VocValueDto[],
      table: {
        tableDataSet: null as null | TableDataSet,
        factory: null as null | TableDataSetFactory,
        edit: false,
        editingVocDataSet: null as null | EditingVocDataSet,
      },
      open: {
        vocEntryModal: null as {
          record_id?: number;
          mode: EditingVocModalMode;
        } | null,
        enterModal: null as null | EnterModalProps,
      },
      helpHelper: new HelpHelper<HelpHelperTableContext>(),
      actions: [] as ActionButtonDto[],
    };
  },
  created() {
    this.$watch(
      () => this.params.voc_type,
      async () => {
        await this.initVocType();
        this.table.edit = false;
        this.initEdit();
      },
    );
    this.$watch(
      () => this.table.edit,
      () => {
        this.initEdit();
      },
    );
  },
  async mounted() {
    await this.initVocType();
  },
  deactivated() {
    this.table.tableDataSet?.destroy();
    this.table.editingVocDataSet?.destroy();
  },
  computed: {
    emptyHelp(): string | undefined {
      if (this.vocExtraInfo?.edit.hint) {
        return "[не задано]";
      }

      return undefined;
    },

    currentTableModelHelp(): TableModel | undefined {
      const context = this.helpHelper.getContext();
      if (!context) {
        return undefined;
      }

      return context.tableDataSet.getModelOrUndefined(context.field);
    },

    currentHelpValue(): string | undefined {
      const model = this.currentTableModelHelp;
      if (!model) {
        return undefined;
      }
      if (model.modelDto.help) {
        return model.modelDto.help;
      }

      return this.emptyHelp;
    },

    params(): { voc_type: string } {
      return this.$route.params as any;
    },

    vocDto(): VocDto | undefined {
      return this.vocList?.find(
        (x: VocDto) => x.voc_type === this.params.voc_type,
      );
    },
  },
  methods: {
    initEdit() {
      const edit = this.table.edit && this.vocRepositoryDto!.edit.edit;
      const hint = this.vocExtraInfo!.edit.hint;
      this.table.tableDataSet!.setEditMode({
        edit,
        hint,
      });
    },

    async initVocType(refresh: RefreshType = "all") {
      if (!this.params.voc_type) {
        return;
      }

      this.vocList = null;
      this.values = null;
      if (refresh === "all") {
        this.actions = [];
        this.vocRepositoryDto = null;
        this.vocExtraInfo = null;
        this.table.tableDataSet = null;
        this.table.factory = null;
      }

      try {
        this.vocList = (await AppApiService.getVocsTypesList()).json.result;
        if (refresh === "all") {
          this.actions = await ApiActionsService.getActions(
            `Vocs.${this.params.voc_type}`,
          );
        }

        if (!this.vocRepositoryDto || refresh === "all") {
          this.vocRepositoryDto = (
            await ApiService.getVocRepository(this.params.voc_type)
          ).json;
        }

        if (!this.vocExtraInfo || refresh === "all") {
          this.vocExtraInfo = await AppApiService.getVocExtraInfo(
            this.params.voc_type,
          );
        }

        await this.initEditingVocDataSet(this.vocRepositoryDto);
      } catch (e) {
        Logger.error({ e });
        const errorText = await ApiHelper.getErrorMessage(e);
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при загрузке",
          text: errorText,
        });
      }
    },

    setHelp(pointer: Required<TableCellPointer> | null) {
      const { tableDataSet } = this.table;
      if (!pointer || !tableDataSet) {
        this.helpHelper.setHelp(null);
        return;
      }

      const model = tableDataSet.getModelOrUndefined(pointer.col_name);
      if (!model) {
        return;
      }

      this.helpHelper.setHelp(model.modelDto.help || this.emptyHelp || null, {
        tableDataSet: this.table.tableDataSet as TableDataSet,
        field: pointer.col_name,
      });
    },

    onOpenHelpEdit() {
      const context = this.helpHelper.getContext();
      if (!context) {
        return;
      }

      const model = this.currentTableModelHelp!;
      const title = `Редактирование подсказки для столбца "${model.caption}"`;
      this.open.enterModal = {
        title,
        modelValue: model.modelDto.help || "",
        onUpdateModelValue: async (value: string) => {
          if (await this.onUpdateHint(model, value)) {
            this.onCloseEnterModal();
          }
        },
        onClose: this.onCloseEnterModal,
      };
    },

    onCloseEnterModal() {
      this.open.enterModal = null;
    },

    async onUpdateHint(model: TableModel, text: string) {
      if (!this.params.voc_type) {
        return;
      }

      try {
        await ApiVocService.changeHint(
          {
            voc_type: this.params.voc_type,
            attr: model.field,
          },
          { text },
        );
        model.modelDto.help = text;
        this.helpHelper.setHelp(null);
      } catch (ex: any) {
        await ApiHelper.wrapNotifyError(ex, { isError: true });
        return false;
      }

      return true;
    },

    onRouteVocByPointer(pointer: TableCellPointer) {
      const tableRow = this.table.tableDataSet?.getRowOrUndefined(pointer.row);
      if (!tableRow) {
        return;
      }

      const vocValueDto: VocValueDto = tableRow.original;
      this.open.vocEntryModal = {
        record_id: vocValueDto.record_id,
        mode: "edit",
      };
    },

    onCellKeyDown({
      event,
      pointer,
    }: {
      event: KeyboardEvent;
      pointer: Required<TableCellPointer>;
    }) {
      if (this.table.edit) {
        if (this.table.tableDataSet!.currentEdit !== null) {
          const vocValueDto: VocValueDto =
            this.table.tableDataSet!.getRowOrException(pointer.row).original;
          if (!vocValueDto.edit?.edit) {
            this.table.tableDataSet?.setCurrentEdit(null);
          }
        }
        return;
      }

      const eventHelper = new KeyboardEventHelper(event);
      if (eventHelper.keyEnter.isAllTrue) {
        this.onRouteVocByPointer(pointer);
        return;
      }
    },

    onCellDblClick({
      event,
      pointer,
    }: {
      event: MouseEvent;
      pointer: Required<TableCellPointer>;
    }) {
      if (this.table.edit) {
        return;
      }

      this.onRouteVocByPointer(pointer);
    },

    onUpdateCells(updates: TableEmitUpdateCell[]) {
      const updatesFiltered = updates.filter((update) => {
        const vocValueDto: VocValueDto = update.original;
        if (vocValueDto && !vocValueDto.edit?.edit) {
          NotificationsService.send({
            type: "warn",
            title: "Внимание",
            text: `Редактирование записи "${vocValueDto.label}" запрещено`,
          });
          return false;
        }

        return true;
      });
      if (updatesFiltered.length) {
        this.table.tableDataSet!.updateCells(updatesFiltered);
      }
    },

    onCellsClear(pointers: Required<TableCellPointer>[]) {
      const pointersFiltered = pointers.filter((pointer) => {
        const vocValueDto: VocValueDto =
          this.table.tableDataSet!.getRowOrException(pointer.row).original;
        if (vocValueDto && !vocValueDto.edit?.edit) {
          NotificationsService.send({
            type: "warn",
            title: "Внимание",
            text: `Редактирование записи "${vocValueDto.label}" запрещено`,
          });
          return false;
        }

        return true;
      });

      if (pointersFiltered.length) {
        this.table.tableDataSet!.updateCells(
          pointersFiltered.map((pointer) => {
            return this.table.tableDataSet!.getEmitUpdateCell({
              changes: ["value"],
              newValue: {
                value: "",
                info: undefined,
              },
              pointer,
            });
          }),
        );
      }
    },

    onRowDelete(pointers: Required<TableCellPointer>[]) {
      const pointersFiltered = pointers.filter((pointer) => {
        const vocValueDto: VocValueDto =
          this.table.tableDataSet!.getRowOrException(pointer.row).original;
        if (vocValueDto && !vocValueDto.edit?.edit) {
          NotificationsService.send({
            type: "warn",
            title: "Внимание",
            text: `Удаление записи "${vocValueDto.label}" запрещено`,
          });
          return false;
        }

        return true;
      });
      if (pointersFiltered.length) {
        this.table.tableDataSet!.rowDelete(pointersFiltered);
      }
    },

    async onPagination(mode: PaginationMode) {
      const {
        tableDataSet,
        editingVocDataSet,
        factory,
      } = this.table;
      if (!editingVocDataSet || !tableDataSet || !factory) {
        throw new Error("data not found");
      }

      await editingVocDataSet.paginationLoad(
        mode,
        tableDataSet as any,
        factory as TableDataSetFactory,
      );
    },

    async initEditingVocDataSet(
      repository: VocRepositoryDto,
      refresh: RefreshType = "all",
    ) {
      if (refresh === "all") {
        this.table.editingVocDataSet = null;
        this.table.factory = null;
        this.table.tableDataSet = null;
      }

      let editingVocDataSet = this.table
        .editingVocDataSet as EditingVocDataSet | null;
      if (!editingVocDataSet || refresh === "all" || refresh === "record") {
        editingVocDataSet = await this.getEditingVocDataSet(repository);
      }

      const data = (await editingVocDataSet.get())!;
      const factory = await this.getTableDataSetFactory(data, repository);

      let tableDataSet = this.table.tableDataSet as TableDataSet | null;
      if (!tableDataSet || refresh === "all") {
        tableDataSet = await factory.create();
      }

      await editingVocDataSet.updateValuesRemain(tableDataSet);
      this.table.tableDataSet = tableDataSet;
      this.table.editingVocDataSet = editingVocDataSet;
      this.table.factory = factory;
      this.initEdit();
      tableDataSet.fixCursor();
    },

    async getEditingVocDataSet(repository: VocRepositoryDto) {
      const staticParams: EditingVocDataSetStaticParams = {
        action: "list_by_type",
        voc_type: this.params.voc_type,
      };
      const serverFilterSort =
        repository.form.table.options?.server_filter_sort !== false;
      const editingVocDataSet = new EditingVocDataSet();
      await editingVocDataSet.setParams({
        staticParams,
        lazyLoadSize: serverFilterSort ? repository.lazy_load_size : undefined,
        filter: serverFilterSort
          ? (this.$route.query.filter as string)
          : undefined,
        getRowAttrs: (x) => VocDataSet.getAttrs(x, repository),
        listUrl: "/api/vocs?",
      });
      return editingVocDataSet;
    },

    async getTableDataSetFactory(
      data: EditingVocDataSetData,
      repository: VocRepositoryDto,
    ) {
      const uniqId = this.params.voc_type;
      const filtersSaveModule = new PanelFiltersSaveModule(`voc_${uniqId}`);
      await filtersSaveModule.init();
      const filtersSet = new PanelFiltersSet(filtersSaveModule);
      filtersSet.subject.subscribe((data) =>
        applyPanelFiltersData(this.table.tableDataSet as TableDataSet, data),
      );
      return new TableDataSetFactory({
        tableName: "voc",
        uniqId,
        headers: repository.form.table.headers,
        modelDtoArray: repository.form.table.model,
        rows: data.values.map((x) => VocDataSet.getAttrs(x, repository)),
        rowsOriginal: data.values,
        types: repository.types,
        repository: new TableVocRepository(),
        getSystemField: (model) => `attrs.${model.field}`,
        tableDataSetOptions: {
          listeners: {
            destroy: {
              next: (tableDataSet) => {
                this.table.editingVocDataSet?.tableDataSetDestroy(tableDataSet);
              },
            },
            changeFilters: {
              next: async () => {
                if (!this.table.editingVocDataSet || !this.table.factory) {
                  throw new Error("not found data");
                }

                await this.table.editingVocDataSet.onChangeFilters(
                  this.table.tableDataSet as any,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
            changeSort: {
              next: async () => {
                if (!this.table.editingVocDataSet || !this.table.factory) {
                  throw new Error("not found data");
                }

                await this.table.editingVocDataSet.onChangeSort(
                  this.table.tableDataSet as any,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
          },
          extra: {
            voc_type: this.params.voc_type,
          },
          helpHelper: this.helpHelper,
          filtersSet,
          display: {
            rowIndexCol: true,
          },
          uniqueWordWrapSave: true,
          optionsDto: repository.form.table.options,
        },
        enableAutoSaveSettings: true,
      });
    },

    async onActionExec(action: ActionButtonDto) {
      if (!this.table?.tableDataSet) {
        return;
      }

      try {
        const tableDataSet = this.table.tableDataSet;
        tableDataSet.updateGlobalContext();
        const { selected_cell } = GlobalContext.get() as PageGlobalContext;
        const selected_row = tableDataSet.getSelectedRow()?.original;
        const result = await ApiActionsService.execute(action, {
          selected_row,
          selected_cell,
        });
        const refresh = result.result?.refresh;
        if (refresh) {
          await this.initVocType(refresh);
        }
      } catch (ex) {
        Logger.error(ex);
        await ApiHelper.wrapNotifyError(ex, { isError: true });
      }
    },
  },
});
