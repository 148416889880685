import { PaginationDataSet } from "table";
import { VocValueDto } from "table/dist/services/Api/types/VocRepositoryDto";

export interface EditingVocDataSetStaticParams {
  action: string;
  voc_type: string;
}

export class EditingVocDataSet extends PaginationDataSet<
  VocValueDto,
  EditingVocDataSetStaticParams
> {
  get voc_type(): string {
    return this.params.staticParams.voc_type;
  }
}
